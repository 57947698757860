import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { PrivacyDE } from "./privacyPolicy/PrivacyDE";
import { PrivacyEN } from "./privacyPolicy/PrivacyEN";
import { PrivacyHE } from "./privacyPolicy/PrivacyHE";

const PrivacyPolicyPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { push } = useHistory();

  return (
    <>
      <div className="p-4 bg-secondary">
        <Header shade="dark" onCloseAction={() => push("/")} />
        <div className="w-full flex justify-center">
          <div className="h-full p-2 pt-28 max-w-3xl w-full flex justify-center flex-col items-center">
            {/* <Typography
              paragraph
              variant="h6"
              color="text-text"
              className="font-semibold"
              style={{
                direction: "ltr",
              }}
            >
              {t("pages.privacyPolicy")}
            </Typography> */}
            <div>
              {(!i18n.language || i18n.language === "de") && <PrivacyDE />}
              {i18n.language === "en" && <PrivacyEN />}
              {i18n.language === "he" && <PrivacyHE />}
            </div>
          </div>
        </div>
      </div>
      <style>{`
      .ql-editor{
       @apply text-text;
      }
      .ql-clipboard{
        display:none;
      }
      `}</style>
    </>
  );
};

export default PrivacyPolicyPage;
