import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";

const IncompatiblePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center h-full px-10 pt-40 bg-background-dark">
      {/* <Header logoOnly /> */}
      <Typography
        variant="h6"
        color="text-white"
        className="font-semibold text-center signika"
      >
        {t("incompatible.title")}
      </Typography>
      <Typography
        // variant="h6"
        color="text-white"
        className="text-center signika"
      >
        {t("incompatible.warning")}
      </Typography>
    </div>
  );
};

export default IncompatiblePage;
