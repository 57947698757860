interface Props {
  shade?: "dark" | "light";
}

const Loader: React.FC<Props> = ({ shade = "dark" }: Props) => {
  return (
    <>
      <div className="lds-dual-ring" />
      <style>
        {`
          .lds-dual-ring {
            display: inline-block;
            width: 80px;
            height: 80px;
          }
          .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 55px;
            height: 55px;
            margin: 8px;
            border-radius: 50%;
            border: 5px solid;
            border-color: ${
              shade === "dark" ? "#63378D" : "white"
            } transparent ${shade === "dark" ? "#63378D" : "white"} transparent;
            animation: lds-dual-ring 1.2s linear infinite;
          }
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </>
  );
};

export default Loader;
