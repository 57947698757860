const itemName = "version";
const latestVersion = "v1.2.2";

export const checkVersion: () => void = () => {
  const currentVersion = localStorage.getItem(itemName);
  if (!currentVersion) localStorage.setItem(itemName, latestVersion);
  if (currentVersion !== latestVersion) {
    localStorage.setItem(itemName, latestVersion);
    // console.log("Must update");
    window.location.reload();
  } else {
    // console.log("Up to date");
  }
};

export const currentVersion: () => string = () => latestVersion;
