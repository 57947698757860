import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import Loader from "components/ui/loader/Loader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useQuery } from "react-query";
import { ContentService } from "../../../services/ContentService";
import { splitLanguage } from "resources/functions/splitLanguage";

interface Props {
  id: string;
  sliderFolder: string;
}

const Slider: React.FC<Props> = ({ id, sliderFolder }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { i18n } = useTranslation();

  const { data: sliderData, isLoading } = useQuery(
    [sliderFolder],
    () => ContentService.getSliderData(id, sliderFolder),
    { enabled: !!(id && sliderFolder), initialData: [] }
  );

  return (
    <div className="relative flex flex-col items-center justify-center bg-secondary-dark">
      <div style={{ direction: "ltr" }}>
        {sliderData.length > 0 && !isLoading && (
          <Carousel
            onChange={(index) => {
              setCurrentSlide(index);
            }}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            dynamicHeight={false}
            autoPlay={false}
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            renderArrowPrev={(clickHandler, hasPrev, label) => {
              return (
                <div
                  title={label}
                  onClick={clickHandler}
                  className="absolute left-0 z-10 flex items-center transform -translate-y-1/2 top-1/2"
                >
                  <Icon path={mdiChevronLeft} size={2} color="white" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext, label) => {
              return (
                <div
                  title={label}
                  onClick={clickHandler}
                  className="absolute right-0 z-10 flex items-center transform -translate-y-1/2 top-1/2"
                >
                  <Icon path={mdiChevronRight} size={2} color="white" />
                </div>
              );
            }}
          >
            {sliderData.map((image, i) => (
              <div key={i} className="relative flex justify-center h-full">
                <div key={i} className="self-center w-full">
                  <img
                    alt={image.imageName || "dataImage"}
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${sliderFolder}/${image.imageName}`}
                    style={{
                      width: "auto",
                      maxHeight: "95vh",
                    }}
                  />
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      background:
                        "linear-gradient(to bottom, #6FE4FC80 0%,rgba(255,255,255,0) 100%)",
                    }}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        )}
        {isLoading && (
          <div className="flex justify-center">
            <Loader shade={"light"} />
          </div>
        )}
      </div>
      <div className="absolute bottom-0 z-10 w-full px-6 py-3 bg-gradient-to-t from-[#000000a1] to-transparent">
        {sliderData.map(
          (image, i) =>
            image.caption &&
            currentSlide === i && (
              <ReactQuill
                key={i}
                className="text-white caption" // classes in index.css
                readOnly
                value={
                  image.caption[splitLanguage(i18n.language)] ||
                  image.caption.de
                }
                modules={{
                  toolbar: null,
                }}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Slider;
