import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

interface SCProps {
  children: ReactNode;
}

const SwipeContainer: React.FC<SCProps> = ({ children }: SCProps) => {
  const history = useHistory();

  const [trans, setTrans] = useState(0);
  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      const { pathname } = history.location;
      setTrans(0);
      if (pathname !== "/") return;
      history.push("/camera");
    },
    onSwipedDown: ({ dir }) => {
      const { pathname } = history.location;
      setTrans(0);
      if (pathname === "/") return;
      history.push("/");
    },
    onSwiping: ({ dir, deltaY }) => {
      const { pathname } = history.location;
      if (dir === "Down" && pathname === "/") return;
      if (dir === "Up" && pathname !== "/") return;
      if (dir === "Right" || dir === "Left") return;
      setTrans(deltaY / 5);
    },
    delta: 50,
  });

  return (
    <div
      className="w-full h-full"
      {...swipeHandlers}
      style={{ transform: `translateY(${trans}%)` }}
    >
      {children}
    </div>
  );
};

export default SwipeContainer;
