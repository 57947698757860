import React, { useEffect } from "react";
import RouteSwitch from "./RouteSwitch";
import { Detector } from "react-detect-offline";
import OfflinePage from "pages/OfflinePage";
import IncompatiblePage from "pages/IncompatiblePage";
import { checkVersion } from "./checkVersion";
import IntroPage from "pages/IntroPage";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { LanguageService } from "../services/LanguageService";
import { changeLanguage } from "../resources/functions/changeLanguage";

const webGLCompatible = () => {
  const canvas = document.createElement("canvas");
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  if (gl && gl instanceof WebGLRenderingContext) {
    return true;
  } else {
    return false;
  }
};

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const { data } = useQuery("language", LanguageService.getLanguageData);

  useEffect(() => {
    // Set language detected to code only if exists in local storage
    if (
      localStorage.getItem("lang") &&
      localStorage.getItem("lang").length > 2
    ) {
      const lang = localStorage.getItem("lang").slice(0, 2);
      localStorage.setItem("lang", lang);
      i18n.changeLanguage(lang);
    }

    checkVersion();
    if (data) {
      changeLanguage(i18n.language, data.rtlLanguages.includes(i18n.language));
    }
  }, [data]);

  return (
    <div className="h-full bg-secondary">
      <IntroPage />
      {webGLCompatible() ? (
        <Detector
          polling={{
            interval: 20000,
          }}
          render={({ online }) => (
            <>
              {!online && <OfflinePage />}
              {online && <RouteSwitch />}
            </>
          )}
        />
      ) : (
        <IncompatiblePage />
      )}
    </div>
  );
};

export default App;
