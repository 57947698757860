import { mdiWifiOff } from "@mdi/js";
import Icon from "@mdi/react";
import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";
// import wifi from "../resources/images/wifi.svg";

const OfflinePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-full px-10 space-y-10 bg-background-dark">
      {/* <Header logoOnly /> */}

      {/* <img src={wifi} alt="wifi" className="m-10" /> */}
      {/* <Icon path={mdiWifiOff} size={4} color="white" /> */}
      <img
        src="/images/wifi.png"
        style={{
          maxHeight: 76,
          maxWidth: 100,
        }}
      />
      <Typography
        variant="h6"
        color="text-white"
        className="font-semibold text-center signika"
      >
        {t("offline.title")}
      </Typography>
      <Typography
        // variant="h6"
        color="text-white"
        className="text-center signika"
      >
        {t("offline.warning")}
      </Typography>
      <button
        onClick={() => {
          location.reload();
        }}
        className="w-full p-3 font-semibold rounded-full bg-primary"
      >
        {t("offline.button.reload")}
      </button>
    </div>
  );
};

export default OfflinePage;
