import { IData } from "pages/contentPage/IData.interface";
import { RequestManager } from "./RequestManager";
import { ISliderData } from "../pages/contentPage/IData.interface";

export const ContentService = {
  getVideo: async ({
    id,
    videoName,
  }: {
    id: string;
    videoName: string;
  }): Promise<string> => {
    const res = await RequestManager.get(
      `/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${videoName}`
    );

    const blob = await res.blob();
    const newBlob = new Blob([blob], { type: "video/mp4" });
    const videourl = window.URL.createObjectURL(newBlob);
    return videourl;
  },
  getContentData: async (id: string): Promise<IData> => {
    const res = await RequestManager.get(
      `/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/data.json`
    );
    const data = await res.json();
    return data as IData;
  },
  getSliderData: async (
    id: string,
    sliderFolder: string
  ): Promise<ISliderData[]> => {
    const res = await RequestManager.get(
      `/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${sliderFolder}/sliderData.json`
    );
    const data = await res.json();
    return data as ISliderData[];
  },
};
