import i18n from "i18n";

type changeLanguageFunction = (lang: string, rtl?: boolean) => void;

export const changeLanguage: changeLanguageFunction = (
  lang,
  rtl = false
): void => {
  const body = document.body;

  if (rtl) {
    body.style.direction = "rtl";
  } else {
    body.style.direction = "ltr";
  }

  i18n.changeLanguage(lang);
  localStorage.setItem("lang", lang);
};
