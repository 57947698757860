import Typography from "../../components/ui/typography/Typography";

export const PrivacyHE = () => {
  return (
    <div>
      <Typography variant="h6">תנאי שימוש</Typography>
      <Typography paragraph>
        ברוכים הבאים לאתר המוזיאון בבית הכנסת החדש בקלינינגרד – מפעילת אתר זה
        תחת התחום jmkaliningrad.org היא אגודת יהודי מזרח פרוסיה, האחראית גם על
        הגשמת התערוכה על תולדות היהודים בבית הכנסת בקלינינגרד.
      </Typography>
      <Typography paragraph>
        הצהרה זו חלה גם על אתרי האינטרנט המספקים מידע מקוון בארבע שפות
        בתערוכה.Copyright
      </Typography>
      <Typography paragraph>
        התוכן וההישגים היצירתיים של האתר כפופים לזכויות היוצרים של העמותה ושל
        מפיקי התערוכה, אלא אם צוין במפורש מקור אחר
      </Typography>

      <Typography variant="h6">הגנת מידע - פרטיות</Typography>
      <Typography paragraph>
        המפעילה מכבדת את פרטיות המבקרים והמשתמשים באתר. קיימת הצהרת הגנה על מידע
        בהתאם לחוק הגרמני והאירופי בגרסה הגרמנית של אתר זה.
      </Typography>
      <Typography paragraph>
        זאת מציינת כי נתונים אישיים נוצרים בעת השימוש באתר זה וכיצד זה מטופל.
      </Typography>
      <Typography paragraph>
        נתונים אלה נאספים באופן אוטומטי או, במקרה של עוגיות, לאחר הסכמתך על ידי
        מערכות ה-IT שלנו בעת ביקורך באתר. מדובר בעיקר בנתונים טכניים (למשל דפדפן
        אינטרנט, מערכת הפעלה או זמן תצוגת הדף).
      </Typography>
      <Typography paragraph>
        המטרה היא להבטיח אספקה ​​ללא שגיאות של האתר ולנתח את התנהגות המשתמשים.
      </Typography>
      <Typography paragraph>
        למבקר באתר הזכות לקבל מאיש קשר את כל המידע על סוג הנתונים, היקפם ומטרתם.
        כמו כן, יש לו הזכות לבקש את הגבלת השימוש ומחיקת הנתונים הללו.
      </Typography>
      <Typography paragraph>
        באופן כללי, ייתכנו פערי אבטחה בהעברת נתונים דרך האינטרנט. הגנה מלאה על
        הנתונים מפני גישה של צדדים שלישיים אינה אפשרית במהלך השידור.
      </Typography>
      <Typography paragraph>
        על מנת להבטיח את ההגנה על הנתונים המאוחסנים, חברת האחסון All-Inkl. נחתם
        חוזה לעיבוד הזמנות (AVV).
      </Typography>
      <Typography paragraph>
        האתר מתארח על ידי הספק ALL-INKL.COM – Neue Medien Münnich, הבעלים René
        Münnich, Hauptstraße 68, 02742 Friedersdorf, Germany. ניתן למצוא פרטים
        בהצהרת הגנת המידע של All-Incl:
      </Typography>

      <Typography paragraph>
        <a
          href="https://all-inkl.com/datenschutzinformationen"
          className="text-blue-400 underline"
        >
          https://all-inkl.com/datenschutzinformationen
        </a>
      </Typography>

      <Typography paragraph>
        החוזה הנדרש על פי חוק הגנת המידע מבטיח שמפעילת האתר תעבד את הנתונים
        האישיים של מבקרי האתר רק בהתאם להנחיות שלנו ובהתאם ל-GDPR (תקנה).
      </Typography>
      <Typography paragraph>
        למשתמש הזכות לקבל מידע על סוג ומטרת הנתונים האישיים המאוחסנים שלו בכל
        עת. כמו כן קיימת הזכות לבקש תיקון או מחיקה של נתונים אלו. הסכמה לעיבוד
        נתונים ניתנת לביטול בכל עת לעתיד. יש לך גם את הזכות להגיש תלונה לרשות
        הפיקוח המוסמכת.
      </Typography>
      <Typography paragraph>
        אתה יכול לפנות למפעיל בכל עת אם יש לך שאלות נוספות בנושא הגנת מידע.
      </Typography>
      <Typography paragraph>מפעילת האתר, אחראית להגנת מידע:</Typography>

      <Typography>
        Juden in Ostpreussen, Verein für Geschichte und Kultur e.V.
      </Typography>
      <Typography>Friedrichstr. 95</Typography>
      <Typography>10117 Berlin</Typography>
      <Typography>Germany</Typography>

      <Typography paragraph>
        איש קשר: מכאל לייזרוביץ דוא"ל: dataprotection@jewsineastprussia.de
      </Typography>

      <Typography paragraph>
        לפרטים מרחיקי לכת עם פרטים על הבסיס המשפטי, אנא עברו לאתר שלנו בשפה
        הגרמנית תחת "Datenschutz" (הגנת מידע).
      </Typography>
    </div>
  );
};
