import { VideoPlayer } from "./VideoPlayer";

export const VideoLangSwitcher: React.FC<any> = ({ lang, data }) => {
  console.log("lang, data : ", lang, data);
  return (
    <VideoPlayer
      key={data.i + lang}
      id={data.id}
      videoName={data.videoName[lang] || data.videoName.de}
      mediaPoster={data.mediaPoster}
      mediaCaption={data.mediaCaption}
    />
  );
};
