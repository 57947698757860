import Typography from "../../components/ui/typography/Typography";

export const PrivacyEN = () => {
  return (
    <div>
      <Typography variant="h6">Data protection – terms of use</Typography>
      <Typography paragraph>
        Welcome to the website of the Museum in the New Synagogue Kaliningrad -
        the operator of this website under the domain jmkaliningrad.org is the
        Association Jews in East Prussia, which is also responsible for the
        realization of the exhibition on the history of the Jews in the
        Kaliningrad Synagogue.
      </Typography>
      <Typography paragraph>
        This statement additionally applies to the websites, which provides
        online information in four languages in the exhibition.
      </Typography>

      <Typography variant="h6">Copyright</Typography>
      <Typography paragraph>
        The contents and the creative achievements of the website are under the
        copyright of the association and the producers of the exhibition, if no
        other source is explicitly mentioned.
      </Typography>

      <Typography variant="h6">Data protection - privacy</Typography>
      <Typography paragraph>
        The operator respects the privacy of the site's surfers and users. There
        is a privacy policy according to German and European law on the German
        language version of this website.
      </Typography>
      <Typography paragraph>
        This statement indicates that personal data is generated when using this
        website and how this data is handled.
      </Typography>
      <Typography paragraph>
        This data is collected automatically or, in the case of cookies, after
        your consent when you visit the website by our IT systems. This is
        mainly technical data (e.g. Internet browser, operating system or time
        of page view).
      </Typography>
      <Typography paragraph>
        The purpose is to ensure error-free provision of the website and to
        analyze user behavior.
      </Typography>
      <Typography paragraph>
        The visitor of the site has the right to obtain from a contact person
        all information about the nature of the data, its scope and purpose. He
        also has the right to request the restriction of use and deletion of
        this data.
      </Typography>
      <Typography paragraph>
        In general, it is possible that security gaps may occur during data
        transmission on the Internet. A complete protection of the data against
        access by third parties is not possible during transmission.
      </Typography>
      <Typography paragraph>
        In order to ensure the protection of the stored data, a contract on
        order processing (AVV) was concluded with the hosting company All-Inkl.
      </Typography>
      <Typography paragraph>
        The website is hosted by the provider ALL-INKL.COM - Neue Medien
        Münnich, Inh. René Münnich, Hauptstraße 68, 02742 Friedersdorf, Germany.
        For details, please refer to the privacy policy of All-Inkl:
      </Typography>
      <Typography paragraph className="text-blue-400 underline">
        <a href="https://all-inkl.com/datenschutzinformationen/">
          https://all-inkl.com/datenschutzinformationen/
        </a>
      </Typography>
      <Typography paragraph>
        In the contract required by data protection law, it is guaranteed that
        the website operator processes the personal data of the website visitors
        only according to our instructions and in compliance with the DSGVO.
      </Typography>
      <Typography paragraph>
        The user has the right to obtain information about the nature and
        purpose of his stored personal data at any time. There is also the right
        to request the correction or deletion of this data. Consent to data
        processing can be revoked at any time for the future. Furthermore, you
        have the right to lodge a complaint with the competent supervisory
        authority.
      </Typography>
      <Typography paragraph>
        You can contact the operator at any time with regard to this and other
        questions on the subject of data protection.
      </Typography>

      <Typography variant="h6">
        Operator of the website, responsible for data protection:
      </Typography>
      <Typography>
        Juden in Ostpreussen, Verein für Geschichte und Kultur e.V.
      </Typography>
      <Typography>Friedrichstr. 95</Typography>
      <Typography>10117 Berlin</Typography>
      <Typography>Germany</Typography>

      <Typography variant="h6">Contact person: Michael Leiserowitz</Typography>
      <Typography paragraph>
        E-Mail: dataprotection@jewsineastprussia.de
      </Typography>
      <Typography>
        For extensive details with information on the legal basis, please go to
        our German-language website below to "Datenschutz" (Data Protection).
      </Typography>
    </div>
  );
};
