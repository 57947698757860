import Header from "components/layout/Header/Header";
import Typography from "components/ui/typography/Typography";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "modern-react-qr-reader";
import { useHistory } from "react-router-dom";
import qrFrameColored from "../resources/images/qr_frame_colored.svg";
import queryString from "query-string";
import IconButton from "components/ui/buttons/IconButton";
import Icon from "@mdi/react";
import { mdiCameraFlip } from "@mdi/js";

const CameraPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [scanSuccess, setScanSuccess] = useState(false);

  const [environment, setEnvironment] = useState(true);

  const QRRef = useRef<HTMLElement>(null);

  const handleScan = (data) => {
    // expected url format ->
    // https://domain.com/content?id={:id}
    // parse id from that, to be able to use history.push()
    if (!data) return;
    const urlObject = queryString.parseUrl(data);
    const id = urlObject.query?.id;
    if (!id) return;
    setScanSuccess(true);
    setTimeout(() => {
      history.push(`/${process.env.REACT_APP_CONTENT_FOLDER}/${id}`);
    }, 2000);
  };

  return (
    <>
      <Header
        shade="light"
        onCloseAction={() => {
          history.push("/");
        }}
      />
      <div className="relative h-full bg-gradient-to-b from-gradient-start via-gradient-middle to-gradient-end">
        <div
          id="qr-scanner"
          className="absolute top-0 left-0 w-full h-full overflow-hidden"
        >
          <QrReader
            ref={QRRef}
            showViewFinder={false}
            onScan={handleScan}
            onError={(e: Error) => console.log(e)}
            facingMode={environment ? "environment" : "user"}
            style={{
              // width: "100%",
              height: "100%",
              // maxWidth: "100vh",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(0%, -50%)",
            }}
          />
        </div>
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          {!scanSuccess && (
            <img
              alt="frame"
              src="/images/scanner_frame.png"
              style={{ maxWidth: "50%", maxHeight: "50%" }}
            />
          )}
          {scanSuccess && (
            <img
              alt="frame"
              src={qrFrameColored}
              style={{ width: "50%", maxHeight: "50%" }}
            />
          )}
        </div>
        <div className="absolute flex justify-center w-full p-2 bottom-5 ">
          <Typography variant="h6" color="text-white" className="font-thin">
            {t("camera.hint")}
          </Typography>
        </div>
        <div className="absolute bottom-0 flex justify-end w-full p-2 ">
          <IconButton onClick={() => setEnvironment((e) => !e)}>
            <Icon path={mdiCameraFlip} size={1} />
          </IconButton>
        </div>
      </div>
      <style>{`
      #qr-scanner section section{
        height:100%
      }
      `}</style>
    </>
  );
};

export default CameraPage;
