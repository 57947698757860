import SwipeContainer from "app/SwipeContainer";
import clsx from "clsx";
import Header from "components/layout/Header/Header";
import ChevronButton from "components/ui/buttons/ChevronButton";
import Typography from "components/ui/typography/Typography";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const HomePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <SwipeContainer>
        <div
          className={clsx(
            "flex flex-col justify-between h-full "
            // "bg-gradient-to-b  from-gradient-start via-gradient-middle to-gradient-end"
          )}
          style={{
            backgroundImage:
              "url(/images/background.png), linear-gradient(to bottom, rgba(237,235,220,1) 0%,rgba(233,235,221,1) 50%,rgba(182,231,235,1) 100%)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="flex flex-col justify-center p-4 pb-10 pt-28">
            <Typography
              color="text-text"
              className="font-thin text-center uppercase signika "
              style={{
                // wordSpacing: "100vw",
                lineHeight: 1.2,
                fontSize: 35,
                textShadow: "0px 0px 5px 1px white",
              }}
            >
              {t("home.content.text.title.line1")}
            </Typography>
            <Typography
              color="text-text"
              className="font-thin text-center uppercase signika "
              style={{
                // wordSpacing: "100vw",
                lineHeight: 1.2,
                fontSize: 35,
                textShadow: "0px 0px 5px 1px white",
              }}
            >
              {t("home.content.text.title.line2")}
            </Typography>
            <Typography
              color="text-text"
              className="font-thin text-center uppercase signika "
              style={{
                // wordSpacing: "100vw",
                lineHeight: 1.2,
                fontSize: 35,
                textShadow: "0px 0px 5px 1px white",
              }}
            >
              {t("home.content.text.title.line3")}
            </Typography>
          </div>
          <div className="flex justify-center">
            <ChevronButton onClick={() => history.push("/camera")} />
          </div>
        </div>
      </SwipeContainer>
    </>
  );
};

export default HomePage;
