import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import queryString from "query-string";
import Loader from "components/ui/loader/Loader";

interface IParams {
  id: string;
}

export const ContentRouter: React.FC = () => {
  const { push, location } = useHistory();
  const { id } = useParams<IParams>();

  useEffect(() => {
    if (!id) {
      if (location.search) {
        const { id } = queryString.parse(location.search);
        id && push(`/${process.env.REACT_APP_CONTENT_FOLDER}/${id}`);
      } else {
        push("/");
      }
    }
  }, [id, location, push]);

  return <Loader shade={"dark"} />;
};
