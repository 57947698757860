import { RequestManager } from "./RequestManager";

export interface ILanguageConfig {
  language: string;
  fallbackLanguage: string;
  supportedLanguages: string[];
  rtlLanguages?: string[];
}

export const LanguageService = {
  getLanguageData: async (): Promise<ILanguageConfig> => {
    const res = await RequestManager.get(
      `/${process.env.REACT_APP_I18N_FOLDER}/config.json`
    );
    const data = await res.json();
    return data;
  },
};
