import clsx from "clsx";
import { ReactNode } from "react";

interface TypeProps {
  children?: ReactNode | string;
  variant?: string;
  color?: string;
  className?: string;
  link?: boolean;
  paragraph?: boolean;
  style?: Record<string, unknown>;
  onClick?: () => void;
}

const variantsMapping: Record<string, unknown> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  body1: "p",
  caption: "p",
};

const Typography: React.FC<TypeProps> = (props: TypeProps) => {
  const {
    variant,
    children,
    color,
    className,
    link,
    paragraph,
    style,
    onClick,
  } = props;
  const Component = variant
    ? (variantsMapping[variant] as keyof JSX.IntrinsicElements)
    : "p";
  const colorString = color
    ? color
    : link
    ? "text-secondary hover:underline cursor-pointer"
    : "text-text-primary";

  const caption = "text-sm";

  return (
    <Component
      onClick={onClick}
      className={clsx(
        "signika",
        className && className,
        colorString,
        variant === "caption" && caption,
        paragraph && "mb-4"
      )}
      style={style}
    >
      {children}
    </Component>
  );
};

export default Typography;
