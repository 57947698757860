import { mdiClose, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import Typography from "components/ui/typography/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ClickAwayListener from "react-click-away-listener";
import IconButton from "components/ui/buttons/IconButton";
import { currentVersion } from "app/checkVersion";
import { LanguageSwitcher } from "components/utils/LanguageSwitcher";

interface IHeader {
  shade?: "dark" | "light";
  onCloseAction?: () => void;
  logoOnly?: boolean;
}

const Header: React.FC<IHeader> = ({
  shade = "dark",
  onCloseAction,
  logoOnly,
}: IHeader) => {
  const { t, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const Logo = ({ src }: { src: string }) => {
    return <img src={src} alt="logo" style={{ height: 80 }} className="p-1" />;
  };

  const imageSrc = {
    en: "/logo/logotype2_en.svg",
    de: "/logo/logotype2.svg",
    he: "/logo/logotype2_he.svg",
  };

  const imageSrcWhite = {
    en: "/logo/logotype2_en_white.svg",
    de: "/logo/logotype2_white.svg",
    he: "/logo/logotype2_he_white.svg",
  };

  return (
    <div className="absolute top-0 left-0 z-50 w-full">
      <div className="flex justify-between p-4">
        <div className="flex space-x-3">
          {shade === "light" && (
            <>
              <Logo src={imageSrcWhite[i18n.language || "de"]} />
            </>
          )}
          {shade === "dark" && (
            <>
              <Logo src={imageSrc[i18n.language || "de"]} />
            </>
          )}
        </div>
        {!logoOnly && (
          <div className="flex items-center">
            <div className="flex flex-col">
              <LanguageSwitcher shade={shade} />
            </div>
            {!onCloseAction && (
              <IconButton onClick={() => setShowMenu(true)}>
                <Icon
                  path={showMenu ? mdiClose : mdiMenu}
                  size={1}
                  className={clsx(
                    shade === "light" && "text-white",
                    shade === "dark" && "text-text"
                  )}
                />
              </IconButton>
            )}
            {onCloseAction && (
              <IconButton onClick={onCloseAction}>
                <Icon
                  path={mdiClose}
                  size={1}
                  className={clsx(
                    shade === "light" && "text-white",
                    shade === "dark" && "text-text"
                  )}
                />
              </IconButton>
            )}
          </div>
        )}
      </div>
      {showMenu && (
        <ClickAwayListener onClickAway={(e) => setShowMenu(false)}>
          <div className={clsx("flex flex-col pt-4 bg-transparent")}>
            <Link to={"/impressum"}>
              <button
                className={clsx(
                  "py-4 border-b border-secondary w-full  hover:bg-primary border-t bg-secondary-dark"
                )}
                onClick={() => setShowMenu(false)}
              >
                <Typography
                  variant="h6"
                  color="text-text"
                  className="text-center signika"
                >
                  {t("pages.impressum")}
                </Typography>
              </button>
            </Link>

            <Link to="/privacy-policy">
              <button
                className={clsx(
                  "py-4 border-b border-secondary w-full  hover:bg-primary bg-secondary-dark"
                )}
                onClick={() => setShowMenu(false)}
              >
                <Typography
                  variant="h6"
                  color="text-text"
                  className="text-center signika"
                >
                  {t("pages.privacy")}
                </Typography>
              </button>
            </Link>
            <div className="flex justify-end p-2 text-white opacity-20">
              <Typography>{currentVersion()}</Typography>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Header;
