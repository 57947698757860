import Subtitle from "components/ui/typography/Subtitle";
import Title from "components/ui/typography/Title";
import { useTranslation } from "react-i18next";
import { IData } from "../IData.interface";
import clsx from "clsx";
import Slider from "./Slider";
import ReactQuill from "react-quill";
import { splitLanguage } from "resources/functions/splitLanguage";
import { MediaObject } from "./MediaObject";

interface Props {
  id: string;
  data: IData;
}

const MediaContent: React.FC<Props> = ({ data, id }: Props) => {
  const { i18n } = useTranslation();

  return (
    <div>
      {data.heroImage && (
        <div className="relative overflow-hidden max-h-96">
          <img
            alt={data.heroImage || "heroImage"}
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${data.heroImage}`}
            className="w-full "
          />
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              background:
                "linear-gradient(to bottom, #6FE4FC80 0%,rgba(255,255,255,0) 100%)",
            }}
          />
        </div>
      )}
      {data.dataArray.map(
        (
          {
            title,
            subtitle,
            text,
            mediaName,
            videoName,
            mediaPoster,
            contentType,
            mediaCaption,
            sliderFolder,
          },
          i
        ) => (
          <div key={i + i18n.language} className="py-3">
            <div className="px-6">
              {title && (
                <Title
                  color={
                    data.backgroundColor === "dark"
                      ? "text-text"
                      : "text-primary"
                  }
                  className="py-3 break-words"
                  style={{ hyphens: "auto" }}
                >
                  {title[splitLanguage(i18n.language)] || title.de}
                </Title>
              )}
              {subtitle && (
                <Subtitle
                  className="py-3"
                  color={
                    data.backgroundColor === "dark"
                      ? "text-text"
                      : "text-primary"
                  }
                >
                  {subtitle[splitLanguage(i18n.language)] || subtitle.de}
                </Subtitle>
              )}
            </div>
            {(contentType === "video" || contentType === "image") &&
              (mediaName || videoName) && (
                <MediaObject
                  key={i + "mediaobject"}
                  contentType={contentType}
                  mediaName={mediaName}
                  videoName={videoName}
                  mediaPoster={mediaPoster}
                  mediaCaption={mediaCaption}
                  i={mediaCaption}
                  id={id}
                />
              )}

            {text && (
              <div className="px-6 py-3">
                <ReactQuill
                  className={clsx(data.backgroundColor)} // sets class in index.css
                  readOnly
                  value={text[splitLanguage(i18n.language)] || text.de}
                  modules={{
                    toolbar: null,
                  }}
                />
              </div>
            )}

            {contentType === "slider" && (
              <Slider sliderFolder={sliderFolder} id={id} />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default MediaContent;
