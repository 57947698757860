import Header from "components/layout/Header/Header";
import Loader from "components/ui/loader/Loader";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { IData } from "./IData.interface";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ContentService } from "services/ContentService";
import Typography from "components/ui/typography/Typography";
import { ContentBody } from "./ContentBody";

const ContentPage: React.FC = () => {
  const { id } = useParams<any>();
  const { push } = useHistory();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery<IData>([{ id: id }], () =>
    ContentService.getContentData(id)
  );

  if (!isError && (!data || isLoading)) {
    return (
      <div className="flex items-center justify-center h-full p-10 pt-28">
        <Loader shade={data?.backgroundColor === "dark" ? "light" : "dark"} />
      </div>
    );
  }

  if (isError) {
    return (
      <>
        <Header
          onCloseAction={() => push("/camera")}
          shade={data?.backgroundColor === "dark" ? "light" : "dark"}
        />
        <div className="flex items-center justify-center h-full p-10 pt-28">
          <Typography> {t("error.noContent")}</Typography>
        </div>
      </>
    );
  }

  return <ContentBody data={data} id={id} />;
};

export default ContentPage;
