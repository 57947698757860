import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { splitLanguage } from "resources/functions/splitLanguage";
import { VideoLangSwitcher } from "./VideoLangSwitcher";

export const MediaObject: React.FC<any> = ({
  contentType,
  mediaName,
  videoName,
  mediaPoster,
  mediaCaption,
  i,
  id,
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      {contentType === "image" && (
        <div className="py-2">
          <div className="flex justify-center bg-black">
            <div className="relative">
              <img
                alt={mediaName || "dataImage"}
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/${mediaName}`}
                className="w-full"
              />
              <div
                className="absolute top-0 left-0 w-full h-full"
                style={{
                  background:
                    "linear-gradient(to bottom, #6FE4FC80 0%,rgba(255,255,255,0) 100%)",
                }}
              />
              {mediaCaption && (
                <div className="absolute bottom-0 z-10 w-full px-6 py-3 bg-gradient-to-t from-[#000000a1] to-transparent">
                  <ReactQuill
                    key={"quill" + i + i18n.language}
                    className="text-white  caption"
                    readOnly
                    value={
                      mediaCaption[splitLanguage(i18n.language)] ||
                      mediaCaption.de
                    }
                    modules={{
                      toolbar: null,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {contentType === "video" && (
        <VideoLangSwitcher
          lang={splitLanguage(i18n.language)}
          data={{
            contentType,
            mediaName,
            videoName,
            mediaPoster,
            mediaCaption,
            i,
            id,
          }}
        />
      )}
    </>
  );
};
