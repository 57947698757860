import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import "./index.css";
import "./i18n";
import { register } from "registerServiceWorker";
import { QueryClient, QueryClientProvider } from "react-query";
import Loader from "components/ui/loader/Loader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-full">
            <Loader shade={"dark"} />
          </div>
        }
      >
        <Router>
          <App />
        </Router>
      </Suspense>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

register();
