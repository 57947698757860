import { useEffect, useState } from "react";
import Typography from "../components/ui/typography/Typography";
import { useTranslation } from "react-i18next";

const IntroPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [showIntro, setShowInto] = useState(
    process.env.NODE_ENV === "development" ? false : true
    // true
  );

  useEffect(() => {
    setTimeout(() => {
      setShowInto(false);
    }, 3000);
  }, []);

  const imageSrc = {
    en: "/logo/logotype2_en.svg",
    de: "/logo/logotype2.svg",
    he: "/logo/logotype2_he.svg",
  };

  if (!showIntro) {
    return null;
  }

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-gradient-start via-gradient-middle to-gradient-end"
      style={{ zIndex: 99999 }}
    >
      <div
        className="flex flex-col h-full animate-fade"
        style={{
          opacity: 0,
        }}
      >
        <div className="flex flex-col items-center justify-center space-y-5 h-3/4 bg-gradient-to-b from-gradient-start via-gradient-middle to-gradient-end">
          <img
            src={imageSrc[i18n.language || "de"]}
            alt="logo"
            style={{
              // maxWidth: 120,
              maxHeight: 200,
              width: "100%",
            }}
          />
          {/* <Typography
            variant="h5"
            color="text-text"
            className="font-thin text-center uppercase signika "
            style={{
              lineHeight: 1.2,
            }}
          >
            {t("home.content.text.main")}
          </Typography> */}
        </div>
        <div className="flex flex-col items-center justify-center bg-white h-1/4">
          <Typography
            color="text-text"
            className="text-center signika"
            style={{
              lineHeight: 1.2,
            }}
          >
            {t("intro.footer.cooperation")}
          </Typography>
          <img
            src="/logo/logotype3x.png"
            alt="logo"
            style={{
              maxWidth: 250,
              maxHeight: 65,
            }}
          />
          <img
            src="/images/germany3x.png"
            alt="logo"
            style={{
              maxWidth: 300,
              maxHeight: 80,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
