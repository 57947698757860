import { IData } from "../IData.interface";

interface Props {
  id: string;
  data: IData;
}

const ARContent: React.FC<Props> = ({ data, id }: Props) => {
  return (
    <iframe
      title="ar"
      className="absolute top-0 left-0 w-full h-full overflow-hidden"
      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_CONTENT_FOLDER}/${id}/ar.html`}
    />
  );
};

export default ARContent;
